<template>
  <v-container>
    <div>
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          size="72"
      />
      <v-card v-else-if="farmer" rounded="lg" class="mt-5">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" md="4">
            <v-card
              @click="takePicture"
              height="200"
              width="200"
              rounded="pill"
            >
              <div class="text-center mb-5" v-if="!farmer.picture">
                <v-icon size="120" color="primary" class="mt-7"
                  >mdi-camera</v-icon
                >
                <p>Take Picture</p>
              </div>
              <v-img
                aspect-ratio="1"
                v-else
                width="650"
                alt="profile pic"
                :src="farmer.picture"
              />
            </v-card>
            <v-card
              @click="takePictureFingerPrint"
              height="200"
              width="200"
              rounded="pill"
            >
              <div class="text-center mb-5" v-if="!farmer.fingerprint_picture">
                <v-icon size="120" color="primary" class="mt-7"
                  >mdi-camera</v-icon
                >
                <p>Add FingerPrint Image</p>
              </div>
              <v-img
                aspect-ratio="1"
                v-else
                alt="profile pic"
                :src="farmer.fingerprint_picture"
              />
            </v-card>
          </v-col>

          <v-col col="12" sm="6" md="8">
            <v-card-title class="justify-start text-h4">
              <span>{{ farmer.full_name }}</span>
              <v-spacer />
              <v-btn :to="{ name: 'agreement' }" rounded text color="red">
                <v-icon left>mdi-file-pdf</v-icon>
                agreement
              </v-btn>
            </v-card-title>
            <v-card-text class="">
              <p class="body-1">
                <v-menu
                    max-width="320"
                    rounded="xl"
                    v-model="show.date_of_birth"
                    :close-on-content-click="false"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      @click="show.date_of_birth = true"
                      readonly
                      v-model="farmer.date_of_birth"
                      label="Date of birth"
                      filled
                      rounded
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker
                      full-width
                      v-model="farmer.date_of_birth"
                    />
                    <v-card-actions>
                      <v-btn
                        @click="show.date_of_birth = false"
                        rounded
                        block
                        color="primary"
                        :disabled="!farmer.date_of_birth"
                        >Continue</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </p>  
              <p class="body-1">
                <!-- <v-icon left>mdi-phone</v-icon>
                Phone number:
                <a class="text-decoration-none" :href="`tel:${farmer.phone}`">{{
                  farmer.phone
                }}</a> -->
                <v-text-field
                  v-model="farmer.phone"
                  label="Phone number"
                  @blur="()=>onFocusOut('phone')"
                  rounded
                  filled
                />
              </p>
              <p class="body-1">
               
                <v-text-field
                  v-model="farmer.ghana_card"
                  label="Ghana Card ID"
                  @blur="()=>onFocusOut('ghana_card')"
                  rounded
                  filled
                />
              </p>
              <p class="body-1">
                <v-icon left>mdi-account-cash</v-icon>
                Momo phone number:
                <v-text-field type="text" name="" v-model="farmer.momo" id="" />
              </p>
              <p class="body-1">
                <!-- <v-icon left>mdi-gender-male-female</v-icon>
                Gender:  -->
                <v-select
                  v-model="farmer.gender"
                  :items="[
                      { text: 'Male', value: 'male' },
                      { text: 'Female', value: 'female' },
                  ]"
                  item-value="value"
                  item-text="text"
                  label="Gender"
                  @change="()=>onFocusOut('gender')"
                  filled
                  rounded
                >
                </v-select>
              </p>
              <!-- <p class="body-1">
                <v-icon left>mdi-map-marker</v-icon>
                Address: {{ farmer.community_id.name }}
              </p>
                Address: {{ farmer.community }}
              </p> -->
              <p class="body-1">
                <v-icon left>mdi-barn</v-icon>
                Farms:
                <v-btn
                  color="success"
                  rounded
                  text
                  :to="{
                    name: 'farmer-farms',
                    params: { farmer_id: farmer._id },
                  }"
                >
                  {{ farmer.farms }} <v-icon right>mdi-eye</v-icon>
                </v-btn>
              </p>
              <v-select
                v-model="farmer.community_id"
                :items="options.communities"
                item-value="_id"
                item-text="name"
                label="Select Community Location"
                filled
                rounded
                
              ></v-select>
              <v-text-field
                v-model.number="farmer.yrs_farming"
                type="number"
                label="Years in farming"
                rounded
                filled
              />
              
              <v-btn
                @click="updateFarmer"
                :loading="isSaving"
                x-large
                color="primary"
                rounded
              >
                Update
              </v-btn>
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider />
        <v-card-text>

          <v-text-field
              v-model="farmer.household_status.replace('Household Status', '')"
              label="Household status"
              @blur="()=>onFocusOut('household_status')"
              rounded
              filled
          />
          <v-text-field
              v-model="farmer.dependents_number"
              type="number"
              @blur="()=>onFocusOut('dependents_number')"
              label="Number of dependents"
              rounded
              filled
          />
          <v-select
            v-model="farmer.marital_status.toLowerCase()"
            :items="options.marital_status"
            item-value="value"
            item-text="text"
            label="Marital Status"
            @change="()=>onFocusOut('marital_status')"
            filled
            rounded
          ></v-select>
          <v-text-field
              v-model="farmer.employment_status"
              label="Employment"
              @blur="()=>onFocusOut('employment_status')"
              rounded
              filled
          />
          <v-text-field
              v-model="farmer.source_income"
              label="Other income source"
              @blur="()=>onFocusOut('source_income')"
              rounded
              filled
          />
          <v-text-field
              v-model="farmer.trainee_status "
              label="Trainee status"
              @blur="()=>onFocusOut('trainee_status')"
              rounded
              filled
          />
          <v-select
            v-model="farmer.level_training"
            :items="options.training_level"
            item-value="value"
            item-text="text"
            @change="()=>onFocusOut('level_training')"
            label="Training Status"
            filled
            rounded/>
          <v-text-field
              v-model="farmer.buying_company"
              label="Buying Company"
              @blur="()=>onFocusOut('buying_company')"
              rounded
              filled
          />

<!--          <ul>-->
<!--            <li>Household status: {{ farmer.household_status }}</li>-->
<!--            <li>Number of dependents: {{ farmer.dependents_number }}</li>-->
<!--            <li>Marital status: {{ farmer.marital_status }}</li>-->
<!--            <li>Employment: {{ farmer.employment_status }}</li>-->
<!--            <li>Education: {{ farmer.educational_level }}</li>-->
<!--            <li>Years farming: {{ farmer.yrs_farming }} years</li>-->
<!--            <li>Other income source: {{ farmer.source_income }}</li>-->
<!--            <li>Trainee status: {{ farmer.trainee_status }}</li>-->
<!--            <li>Training level: {{ farmer.level_training }}</li>-->
<!--            <li>Buying Company: {{ farmer.buying_company }}</li>-->
<!--          </ul>-->
        </v-card-text>
        <v-progress-circular
            v-if="isSaving"
            indeterminate
            color="primary"
            size="72"
        />
      </v-card>
    </div>

    <v-snackbar v-model="snackbar"  absolute bottom>
      Farmer Saved!!
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Plugins, CameraResultType } from "@capacitor/core";
import { v4 as uuid } from "uuid";

const { Camera } = Plugins;

dayjs.extend(relativeTime);

export default {
  name: "FarmerDetails",
  data: () => ({
    farmer: null,
    show: { date_of_birth: false },
    isSaving: false,
    loading: true,
    snackbar: false,
    upload_image: {
      ref: uuid(),
      is_uploaded: false,
      is_form_submitted: false,
    },
    options: {
      marital_status: [
        { text: "Single", value: "single" },
        { text: "Married", value: "married" },
        { text: "Divorced", value: "divorced" },
        { text: "Widowed", value: "widowed" },
      ],
      training_level: [
        { text: "Beginner", value: 1 },
        { text: "Intermediate", value: 2 },
        { text: "Advanced", value: 3 },
        { text: "Expert", value: 4 },
      ],
      compliance_options: [
        { value: "restricted", text: "Restricted" },
        { value: "protected", text: "Protected" },
        { value: "not_applicable", text: "Not Applicable" },
      ],
      communities: [],
    },
  }),
  methods: {
    async takePicture() {
      const { dataUrl } = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.DataUrl,
      });
      const img_data = await fetch(dataUrl);
      this.saveImage(await img_data.blob());
    },
    saveImage(image) {
      this.$firebase
        .storage()
        .ref(`farmer_profiles/${this.upload_image.ref}`)
        .put(image)
        .then((result) => {
          return result.ref.getDownloadURL();
        })
        .then((url) => {
          this.farmer.picture = url;
        });
    },
    getDate(date) {
      return dayjs(date).format("DD MMM, YYYY");
    },
    relativeTime(date) {
      return dayjs(date).fromNow(true);
    },
    async takePictureFingerPrint() {
      const { dataUrl } = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.DataUrl,
      });
      const img_data = await fetch(dataUrl);
      this.saveFingerPrintImage(await img_data.blob());
    },
    saveFingerPrintImage(image) {
      this.$firebase
        .storage()
        .ref(`farmer_fingerprints/${this.upload_image.ref}`)
        .put(image)
        .then((result) => {
          return result.ref.getDownloadURL();
        })
        .then((url) => {
          this.farmer.fingerprint_picture = url;
        });
    },
    updateFarmer() {
      this.isSaving = true;
      this.$axios
        .put(`farmer/${this.$route.params.farmer_id}`, {
          id: this.$route.params.farmer_id,
          gender: this.farmer.gender,
          date_of_birth: this.farmer.date_of_birth,
          phone: this.farmer.phone,
          community_id: this.farmer.community_id,
          momo: this.farmer.momo,
          ghana_card: this.farmer.ghana_card,
          yrs_farming: this.farmer.yrs_farming,
          compliance: this.farmer.compliance,
          fingerprint_picture: this.farmer.fingerprint_picture,
          picture: this.farmer.picture
        })
        .then(() => {})
        .finally(() => {
          this.isSaving = false;
          this.snackbar = true;
        });
    },
    onFocusOut(field) {
     this.isSaving= true;
      this.$axios
        .put(`farmer/${this.$route.params.farmer_id}`, {
          id: this.$route.params.farmer_id,
            [field]: this.farmer[field],
        })
        .then(() => {})
        .finally(() => {
          this.isSaving = false;
          this.snackbar = true;
        });
    },
  },
  mounted() {
    Promise.all([
      this.$axios.get(`farmer/${this.$route.params.farmer_id}`),
      this.$axios.get("/communities"),
    ])
      .then((res) => {
        this.farmer = res[0].data;
        this.options.communities = res[1].data;
      })
      .finally((_) => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
li {
  margin-top: 10px;
}
</style>
